import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';



const commonStyle = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex', 
      flexDirection: 'column', 
      padding: 10, 
      marginTop: 10,
      marginBottom: 10,
      //justifyContent: 'space-around',
      //alignItems: 'flex-start'
      
    },
    formRow: {
      display: 'flex', 
      flexDirection: 'row',
      marginBottom: 20,
      //justifyContent: 'space-between',
      gap: 10,
      flexWrap: 'wrap',
      //backgroundColor: '#0ff5'
    },
    formCol: {
      display: 'flex', 
      flexDirection: 'column',
      //justifyContent: 'space-around',
      // marginRight: 20,
      // marginBottom: 20,
      //backgroundColor: '#00f5'
    },
    formField: {
      flex: 1,
      minWidth: 250,
      // borderColor: '#f00',
      // borderWidth: 2,
      // borderStyle: 'solid' 
    },
    formActionRow: {
      display: 'flex', 
      flexDirection: 'row',
      margin: 10,
      justifyContent: 'flex-end',
      gap: 10,
      flexWrap: 'wrap',
      //backgroundColor: '#0ff5'
    },
    row: {
      display: 'flex', 
      flexDirection: 'row',
      gap: 10,
      flexWrap: 'wrap'
    },
    col: {
      display: 'flex', 
      flexDirection: 'column'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: '#555c',
      color: '#fff',
      flexDirection: 'column'
    },
    backdropDark: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: '#ddd7',
      color: '#fff',
      flexDirection: 'column'
    },

    modal: {
      borderRadius: "6px"
    },
    modalHeader: {
      borderBottom: "none",
      paddingTop: "24px",
      paddingRight: "24px",
      paddingBottom: "0",
      paddingLeft: "24px",
      minHeight: "16.43px"
    },
    modalTitle: {
      margin: "0",
      lineHeight: "1.42857143",
      // fontFamily: "Avenir Black",
      fontWeight: "bold",
      fontSize: "1.5em",
    },
    modalCloseButton: {
      color: "#999999",
      marginTop: "-12px",
      WebkitAppearance: "none",
      padding: "0",
      cursor: "pointer",
      background: "0 0",
      border: "0",
      fontSize: "inherit",
      opacity: ".9",
      textShadow: "none",
      fontWeight: "bold",
      lineHeight: "1",
      float: "right"
    },
    modalClose: {
      width: "16px",
      height: "16px"
    },
    modalBody: {
      paddingTop: "24px",
      paddingRight: "24px",
      paddingBottom: "16px",
      paddingLeft: "24px",
      position: "relative"
    },
    modalFooter: {
      padding: "15px",
      textAlign: "right",
      paddingTop: "0",
      margin: "0"
    },
    modalFooterCenter: {
      marginLeft: "auto",
      marginRight: "auto"
    },

    printContent:{
      '@media print' : {
        // backgroundColor: '#ccc',
        color: '#000 !important', 
        // height: '100%',
        // width: '100%',
        // position: 'absolute',
        // top: 0,
        // left: 0,
        // margin: 0,
        // padding: 15,
        // fontSize: 14,
        // lineHeight: 18,
        display: 'flex !important'
      },
      display: 'none !important'
    },
    nonPrintContent: {
      '@media print' : {
        display: 'none !important'
      }
    },

    errorText: {
      color: '#f44336',
      fontSize: 12
    }
  }),
);

export default commonStyle;
