// import jwt_decode from "jwt-decode";
// import moment from 'moment';
import strapi from '@justnice/strapi-sdk';
import Helper from '@justnice/helper';
import qs from 'qs';
const axios = require('axios').default;

var jwtToken = '';

// Define the storage delegation method for getToken
const getToken = () => {
  jwtToken = localStorage.getItem('jwt');
  return jwtToken;
}

// Define the storage delegation method for setToken
const setToken = (token) => {
  localStorage.setItem('jwt', token.jwt);
  localStorage.setItem('username', token.user.username);
  localStorage.setItem('userid', token.user.id);
  jwtToken = token.jwt;
}

// Initalise the Strapi SDK
strapi.initStrapi(process.env.GATSBY_API_URL, getToken, setToken);

class Api {

  static authProviderLoginUrl(provider) {
    return strapi.authProviderLoginUrl(provider);
  }

  static async authProviderLoginCallback(provider, tokens) {
    return await strapi.authProviderLoginCallback(provider, tokens);
  }

  static async myProfileGet() {
    return await strapi.get('app-users/my_profile');
  }

  static async myProfileUpdate(profile) {
    return await strapi.put(`app-users`, profile);
  }

  static async appMenuGetList(locale) {
    return await strapi.get(`app-menus?_locale=${locale}`);
  }

  static async tagList() {
    return await strapi.get(`tags/list?_sort=value:ASC`);
  }

  static async systemListGet(locale) {
    return await strapi.get(`lists?_sort=category:ASC,sort:ASC&_locale=${locale}`);
  }

  static async assetCreate(formData, onUploadProgress) {
    return await strapi.post(`assets`, formData, null, onUploadProgress);
  }

  static async assetUpdate(formId, formData, onUploadProgress) {
    return await strapi.put(`assets/${formId}`, formData, null, onUploadProgress);
  }

  static async assetDelete(formId) {
    return await strapi.delete(`assets/${formId}`, null);
  }

  static async assetOwnerListGet(query) {
    let queryFilter = {
      _where: []
    };

    if(Helper.stringHasValue(query.keyword.value)) {
      queryFilter._where.push({
        _or: [
          { title_contains: query.keyword.value },
          { filename_contains: query.keyword.value },
          { description_contains: query.keyword.value }
        ]
      });
    }

    if(Helper.stringHasValue(query.cameraModel.value)) {
      queryFilter._where.push({ 'camera_setting.model_contains': query.cameraModel.value});
    }

    if(Helper.stringHasValue(query.location.value)) {
      queryFilter._where.push({ 'location.name_contains': query.location.value});
    }

    if(Helper.stringHasValue(query.imageFormat.value)) {
      queryFilter._where.push({ 'image_format_contains': query.imageFormat.value});
    }

    if(Helper.stringHasValue(query.imageMedium.value)) {
      queryFilter._where.push({ 'image_medium_contains': query.imageMedium.value});
    }

    // Remove the fields that are already added into the queryFilter
    delete query.keyword;
    delete query.cameraModel;
    delete query.location;
    delete query.imageFormat;
    delete query.imageMedium;

    return await strapi.post(`assets/manage?${qs.stringify(queryFilter)}&_sort=createdAt:DESC`, query);
  }

  static async assetDownload(assetId) {
    try {
      let headers = {};
      
      headers.Authorization = 'Bearer ' + jwtToken; 

      const response = await axios.get(`${process.env.GATSBY_API_URL}/assets/download/${assetId}`, {
        responseType: 'arraybuffer',
        headers: headers
      });
      
      return response;
    } catch (error) {
      return error;
    }

    // return await strapi.get(`assets/download/${assetId}`, {responseType: 'arraybuffer'});
  }

  static async assetViewCountInc(assetId) {
    return await strapi.put(`assets/viewcnt/${assetId}`, null);
  }

  static async showCasePhotoListGet(query, page, pageSize=50) {
    let queryFilter = {
      _where: []
    };

    if(Helper.stringHasValue(query?.keyword?.value)) {
      let tags = query.keyword.value.split(' ');
      // console.log('showCasePhotoListGet Tag: ', tags);

      queryFilter._where.push({
        _or: [
          { title_contains: query.keyword.value },
          { description_contains: query.keyword.value },
          { filename_contains: query.keyword.value },
          { tag_contains: tags }
        ]
      });
    }

    // if(Helper.stringHasValue(query.paymentType.value)) {
    //   queryFilter._where.push({ 'payment.payment_type': query.paymentType.value});
    // }

    // if(Helper.stringHasValue(query.orderStatus.value)) {
    //   queryFilter._where.push({ 'status': query.orderStatus.value});
    // }

    // if(Helper.stringHasValue(query.amount.start) && Helper.stringHasValue(query.amount.end)) {
    //   queryFilter._where.push({ 'charge_amount_gte': (Number.parseInt(query.amount.start) * 100).toString()});
    //   queryFilter._where.push({ 'charge_amount_lte': (Number.parseInt(query.amount.end) * 100).toString()});
    // }

    // if(Helper.stringHasValue(query.orderDate.start) && Helper.stringHasValue(query.orderDate.end)) {
    //   queryFilter._where.push({ 'order_date_gte': query.orderDate.start});
    //   queryFilter._where.push({ 'order_date_lte': query.orderDate.end});
    // }

    // return await strapi.get(`orders/?${qs.stringify(queryFilter)}&_sort=order_number:DESC`);

    return await strapi.get(`assets/showcase?_sort=createdAt:DESC&page=${page}&pageSize=${pageSize}&${qs.stringify(queryFilter)}`);
  }

  static async showCasePhotoItemGet(assetId) {
    return await strapi.get(`assets/showcase?id=${assetId}`);
  }

  static async cartListGet() {
    return await strapi.get(`cart-items/?_sort=createdAt:ASC`);
  }

  static async cartItemAdd(item) {
    return await strapi.post(`cart-items`, item);
  }

  static async cartItemAddMultiple(items) {
    return await strapi.post(`cart-items/addmultiple`, items);
  }

  static async cartItemRemove(itemId) {
    return await strapi.delete(`cart-items/${itemId}`);
  }

  static async cartItemRemoveAll() {
    return await strapi.delete(`cart-items/removeall`);
  }

  static async appContentListGet(locale) {
    return await strapi.get(`app-contents?_locale=${locale}`);
  }

  static async paymentNew(paymentInfo) {
    return await strapi.post(`payments`, paymentInfo);
  }

  static async orderGetByPid(pid) {
    let queryFilter = {
      _where: []
    };

    queryFilter._where.push({ 'payment.id': pid});
    queryFilter._where.push({ 'status': 'FULFILLED'});
    
    return await strapi.get(`orders?${qs.stringify(queryFilter)}`);
  }

  static async ownerOrderListGet(query) {
    let queryFilter = {
      _where: []
    };

    if(Helper.stringHasValue(query.keyword.value)) {
      queryFilter._where.push({
        _or: [
          { order_number_contains: query.keyword.value },
          { remark_contains: query.keyword.value },
          { desc_contains: query.keyword.value }
        ]
      });
    }

    if(Helper.stringHasValue(query.paymentType.value)) {
      queryFilter._where.push({ 'payment.payment_type': query.paymentType.value});
    }

    if(Helper.stringHasValue(query.orderStatus.value)) {
      queryFilter._where.push({ 'status': query.orderStatus.value});
    }

    if(Helper.stringHasValue(query.amount.start) && Helper.stringHasValue(query.amount.end)) {
      queryFilter._where.push({ 'charge_amount_gte': (Number.parseInt(query.amount.start) * 100).toString()});
      queryFilter._where.push({ 'charge_amount_lte': (Number.parseInt(query.amount.end) * 100).toString()});
    }

    if(Helper.stringHasValue(query.orderDate.start) && Helper.stringHasValue(query.orderDate.end)) {
      queryFilter._where.push({ 'order_date_gte': query.orderDate.start});
      queryFilter._where.push({ 'order_date_lte': query.orderDate.end});
    }

    return await strapi.get(`orders/?${qs.stringify(queryFilter)}&_sort=order_number:DESC`);
  }

  static async ownerOrderItemListGet(orderId) {
    return await strapi.get(`order-items/byorder/${orderId}?_sort=item_sn:ASC`);
  }

  static async feedbackFormNew(form) {
    return await strapi.post(`feedback-forms`, form);
  }
}

export default Api;